:root {
    --font-default: 'DINWebPro', sans-serif;
    --font-light: 'DINWebPro-Light', sans-serif;
    --font-medium: 'DINWebPro-Medium', sans-serif;
    --font-bold: 'DINWebPro-Bold', sans-serif;

    --color-default: #333;
    --color-primary: #0f4196;
    --color-primary-dark: #00205a;
    --color-primary-trans: rgba(0,32,90, 0.9);
    --color-secondary: #e60000;
    --color-secondary-trans: rgba(230,0,0, 0.9);
    --color-neutral: #cacbcc;
    --color-grey-dark: #a7a7a6;
    --color-grey: #e8e8e8;
    --color-darkgrey: #eae9e9;
    --color-lightgrey: #f7f7f7;
    --color-muted:#A8A8A5;
    --color-danger: #E30613;
    --color-info: #0f4196;
    --color-text-success: #69B131;
    --color-warning: #eaad40;

    --color-bg-danger: #eba0a7;
    --color-bg-info: #dfe4ec;
    --color-bg-success: #E5EFDD;
    --color-bg-warning: #fbd589;

    --gutter-size-mobile: 15px;

    --color-validation-success: var(--color-text-success);
    --color-validation-error: var(--color-danger);
    --color-validation-warning: var(--color-warning);

    --color-progress-default: #eaad40;
    --color-progress-none: #e93743;
    --color-progress-partial: #eaad40;
    --color-progress-full: #5aa332;

    --color-winter: #32b0e4;
    --color-winter-dark: #00205a;
    --color-bg-season: var(--color-winter-dark);
    --color-bg-season-contrast: #fff;
}