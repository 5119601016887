@import 'slick-carousel/slick/slick.css';

:root {
    --slider-arrow-color: #fff;
    --slider-dot-background: var(--color-dark-grey);
    --slider-dot-color: var(--color-dark-grey-contrast);
    --slider-dot-active-background: var(--color-primary);
    --slider-dot-active-color: var(--color-primary-contrast);
    --slider-dot-size: 2rem;
    --slider-dot-margin-top: 1rem;
}

.slider:not(.slick-initialized) > div + div {
    display: none;
}

.slider {
    margin-bottom: calc(var(--slider-dot-size) + var(--slider-dot-margin-top));
}

.slider--same-height .slick-track,
.slider--same-height .slick-slide,
.slider--same-height .slick-slide > div {
    display: flex;
    align-content: stretch;
    flex-direction: row;
    width: 100%;
}
.slider--same-height .slick-slide > div > div {
    display: flex !important; /*override inline style*/
    flex-direction: column;
}
.slider--same-height .slick-slide {
    height: auto;
}

/* arrows */
.slider .slick-arrow {
    display: block;
    background: none;
    border: none;
    color: var(--slider-arrow-color);
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transition: opacity 120ms;
    transform: translateY(-50%);
    text-indent: -9999em;
    font-size: 0;
    height: auto;
    width: auto;
    z-index: 1;
    padding: 1rem;

    &:before {
        content: var(--icon-arrow-left);
        font-size: 2rem;
        text-indent: 0;
        opacity: 1;
        display: inline-block;
        font-family: iconfont;
        speak: none;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0);
    }
}
.slider .slick-next {
    left: auto;
    right: 0;

    &:before {
        content: var(--icon-arrow-right);
    }
}

.slider .slick-arrow.slick-disabled {
    opacity: .5;
}

.slider--hide-arrows .slick-arrow {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

/* dots */
.slider .slick-dots {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: var(--slider-dot-margin-top);
    list-style: none;
    padding: 0;
    text-align: center;
}
.slider .slick-dots > li {
    display: inline-block;
    margin-left: .25rem;
    margin-right: .25rem;
}
.slider .slick-dots > li > button {
    background: var(--slider-dot-background);
    color: var(--slider-dot-color);
    border: none;
    border-radius: 50%;
    width: var(--slider-dot-size);
    height: var(--slider-dot-size);
    padding: 0;
    text-align: center;
    cursor: pointer;
}
.slider .slick-dots > li > button:hover,
.slider .slick-dots > li > button:focus,
.slider .slick-dots > .slick-active > button {
    background: var(--slider-dot-active-background);
    color: var(--slider-dot-active-color);
}

.slider--hide-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}







