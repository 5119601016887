/*! purgecss start ignore */
.auto-search-wrapper {
    display: block;
    position: relative;
    width: 100%
}

/*.auto-search-wrapper input {*/
/*    border: 1px solid #d7d7d7;*/
/*    box-shadow: none;*/
/*    box-sizing: border-box;*/
/*    font-size: 16px;*/
/*    padding: 12px 45px 12px 10px;*/
/*    width: 100%*/
/*}*/

/*.auto-search-wrapper input:focus {*/
/*    border: 1px solid #858585;*/
/*    outline: none*/
/*}*/

.auto-search-wrapper input::-ms-clear {
    display: none
}

.auto-search-wrapper ul {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0
}

/*.auto-search-wrapper ul li {*/
/*    cursor: pointer;*/
/*    margin: 0;*/
/*    overflow: hidden;*/
/*    padding: 10px;*/
/*    position: relative*/
/*}*/

/*.auto-search-wrapper ul li:not(:last-child) {*/
/*    border-top: none*/
/*}*/

/*.auto-search-wrapper ul li[disabled] {*/
/*    background: #ececec;*/
/*    opacity: .5;*/
/*    pointer-events: none*/
/*}*/

/*.auto-search-wrapper .auto-expanded {*/
/*    border: 1px solid #858585;*/
/*    outline: none*/
/*}*/

/*.auto-search-wrapper.loupe:before {*/
/*    filter: invert(60%)*/
/*}*/

.auto-is-loading:after {
    animation: auto-spinner .6s linear infinite;
    border-color: #d9d9d9 grey grey #d9d9d9;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    box-sizing: border-box;
    content: "";
    height: 20px;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 0;
    width: 20px
}

.auto-is-loading .auto-clear {
    display: none
}

@keyframes auto-spinner {
    to {
        transform: rotate(1turn)
    }
}

/*li.loupe:before {*/
/*    bottom: auto;*/
/*    top: 15px*/
/*}*/

/*.loupe input {*/
/*    padding: 12px 45px 12px 35px*/
/*}*/

/*.loupe:before {*/
/*    background-image: var(--loupe-icon);*/
/*    bottom: 0;*/
/*    content: "";*/
/*    height: 17px;*/
/*    left: 10px;*/
/*    margin: auto;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    width: 17px*/
/*}*/

/*.auto-selected:before {*/
/*    opacity: 1*/
/*}*/

.auto-clear {
    align-items: center;
    background-color: transparent;
    border: none;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: center;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px
}

.auto-clear:before {
    content: var(--close-button);
    height: 24px;
    line-height: 100%;
    width: 24px
}

.auto-clear span {
    display: none
}

.auto-results-wrapper {
    background-color: #fff;
    border: 1px solid #858585;
    border-top: none;
    box-sizing: border-box;
    display: none;
    overflow: hidden
}

.auto-results-wrapper ul > .loupe {
    padding-left: 40px
}

.auto-results-wrapper.auto-is-active {
    display: block;
    margin-top: -1px;
    position: absolute;
    /*width: 100%;*/
    /*z-index: 99999*/
}

/*.auto-selected {*/
/*    background-color: #e6e6e6*/
/*}*/

/*.auto-selected + li:before {*/
/*    border-top: none*/
/*}*/

/*.auto-error {*/
/*    border: 1px solid #ff3838*/
/*}*/

/*.auto-error::placeholder {*/
/*    color: #f66;*/
/*    opacity: 1*/
/*}*/

.hidden {
    display: none
}
/*! purgecss end ignore */