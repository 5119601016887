$enable-smooth-scroll: false;
$enable-dark-mode: false;

$primary:       #0f4196;
$secondary:     #e60000;
$success:       #69B131;
$info:          #0f4196;
$warning:       #eaad40;
$danger:        #E30613;
$text-muted:    #A8A8A5;

$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         1rem;
$input-font-family:           var(--font-medium);

$pagination-padding-x: .625rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-border-color: #eae9e9;

$form-check-input-width: 1.25em;
$form-check-input-width: 1.25em;

$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-xl: 1040px;

$pagination-border-radius: 0;
$pagination-border-color: transparent;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;
$pagination-hover-color: #fff;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: transparent;


/*$nav-pills-border-radius: 0;
$nav-pills-link-active-color: #333;
$nav-pills-link-active-bg: #f8f8f8;*/
$accordion-border-radius: 0;
$accordion-btn-bg:transparent;
$accordion-inner-border-radius:0;

$tooltip-max-width: 320px;

$aspect-ratios: (
    "hero-portal": calc(9/16*100%),
    "1x1": calc(100%),
    "2x1": calc(50%),
    "3x2": calc(2/3*100%),
    "16x9": calc(9/16*100%),
    "8x3": calc(3/8*100%),
    "8x7": calc(7/8*100%),
    "3x4": calc(4/3*100%),
    "4x3": calc(3/4*100%),
    "2x3": calc(3/2*100%),
    "7x4": calc(4/7*100%),
    "1920x500":calc(500/1920*100%),
);

$aspect-ratios-xs: (
    "hero-portal": calc(800/375*100%)
);

