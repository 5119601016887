.dp__input_wrap {
    position: relative;
    width: 100%;
    box-sizing: unset
}

.dp__input_wrap:focus {
    border-color: var(--dp-border-color-hover);
    outline: none
}

.dp__input {
    background-color: var(--dp-background-color);
    border-radius: var(--dp-border-radius);
    font-family: var(--dp-font-family);
    border: 1px solid var(--dp-border-color);
    outline: none;
    transition: border-color .2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    font-size: var(--dp-font-size);
    line-height: calc(var(--dp-font-size) * 1.5);
    padding: var(--dp-input-padding);
    color: var(--dp-text-color);
    box-sizing: border-box
}

.dp__input::placeholder {
    opacity: .7
}

.dp__input:hover {
    border-color: var(--dp-border-color-hover)
}

.dp__input_reg {
    caret-color: rgba(0, 0, 0, 0)
}

.dp__input_focus {
    border-color: var(--dp-border-color-hover)
}

.dp__disabled {
    background: var(--dp-disabled-color)
}

.dp__disabled::placeholder {
    color: var(--dp-disabled-color-text)
}

.dp__input_icons {
    display: inline-block;
    width: var(--dp-font-size);
    height: var(--dp-font-size);
    stroke-width: 0;
    font-size: var(--dp-font-size);
    line-height: calc(var(--dp-font-size) * 1.5);
    padding: 6px 12px;
    color: var(--dp-icon-color);
    box-sizing: content-box
}

.dp__input_icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);
    color: var(--dp-icon-color)
}

.dp__clear_icon {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--dp-icon-color)
}

.dp__input_icon_pad {
    padding-inline-start: var(--dp-input-icon-padding)
}

.dp__input_valid {
    box-shadow: 0 0 var(--dp-border-radius) var(--dp-success-color);
    border-color: var(--dp-success-color)
}

.dp__input_valid:hover {
    border-color: var(--dp-success-color)
}

.dp__input_invalid {
    box-shadow: 0 0 var(--dp-border-radius) var(--dp-danger-color);
    border-color: var(--dp-danger-color)
}

.dp__input_invalid:hover {
    border-color: var(--dp-danger-color)
}

.dp__menu {
    background: var(--dp-background-color);
    border-radius: var(--dp-border-radius);
    min-width: var(--dp-menu-min-width);
    font-family: var(--dp-font-family);
    font-size: var(--dp-font-size);
    user-select: none;
    border: 1px solid var(--dp-menu-border-color);
    box-sizing: border-box
}

.dp__menu::after {
    box-sizing: border-box
}

.dp__menu::before {
    box-sizing: border-box
}

.dp__menu:focus {
    border: 1px solid var(--dp-menu-border-color);
    outline: none
}

.dp--menu-wrapper {
    position: absolute;
    z-index: 99999
}

.dp__menu_inner {
    padding: var(--dp-menu-padding)
}

.dp--menu--inner-stretched {
    padding: 6px 0
}

.dp__menu_index {
    z-index: 99999
}

.dp__menu_readonly, .dp__menu_disabled {
    position: absolute;
    inset: 0;
    z-index: 1
}

.dp__menu_disabled {
    background: rgba(255, 255, 255, .5);
    cursor: not-allowed
}

.dp__menu_readonly {
    background: rgba(0, 0, 0, 0);
    cursor: default
}

.dp__arrow_top {
    left: 50%;
    top: 0;
    height: 12px;
    width: 12px;
    background-color: var(--dp-background-color);
    position: absolute;
    border-inline-end: 1px solid var(--dp-menu-border-color);
    border-top: 1px solid var(--dp-menu-border-color);
    transform: translate(-50%, -50%) rotate(-45deg)
}

.dp__arrow_bottom {
    left: 50%;
    bottom: 0;
    height: 12px;
    width: 12px;
    background-color: var(--dp-background-color);
    position: absolute;
    border-inline-end: 1px solid var(--dp-menu-border-color);
    border-bottom: 1px solid var(--dp-menu-border-color);
    transform: translate(-50%, 50%) rotate(45deg)
}

.dp__action_extra {
    text-align: center;
    padding: 2px 0
}

.dp--preset-dates {
    padding: 5px;
    border-inline-end: 1px solid var(--dp-border-color)
}

@media only screen and (max-width: 600px) {
    .dp--preset-dates {
        display: flex;
        align-self: center;
        border: none;
        overflow-x: auto;
        max-width: calc(var(--dp-menu-width) - var(--dp-action-row-padding) * 2)
    }
}

.dp__sidebar_left {
    padding: 5px;
    border-inline-end: 1px solid var(--dp-border-color)
}

.dp__sidebar_right {
    padding: 5px;
    margin-inline-end: 1px solid var(--dp-border-color)
}

.dp--preset-range {
    display: block;
    padding: 5px;
    white-space: nowrap;
    color: var(--dp-text-color);
    border-radius: var(--dp-border-radius);
    transition: var(--dp-common-transition)
}

.dp--preset-range:hover {
    background-color: var(--dp-hover-color);
    cursor: pointer
}

@media only screen and (max-width: 600px) {
    .dp--preset-range {
        border: 1px solid var(--dp-border-color);
        margin: 0 3px
    }

    .dp--preset-range:first-child {
        margin-left: 0
    }

    .dp--preset-range:last-child {
        margin-right: 0
    }
}

.dp__menu_content_wrapper {
    display: flex
}

@media only screen and (max-width: 600px) {
    .dp__menu_content_wrapper {
        flex-direction: column-reverse
    }
}

.dp__calendar_header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dp-text-color);
    white-space: nowrap;
    font-weight: bold
}

.dp__calendar_header_item {
    text-align: center;
    flex-grow: 1;
    height: var(--dp-cell-size);
    padding: var(--dp-cell-padding);
    width: var(--dp-cell-size);
    box-sizing: border-box
}

.dp__calendar_row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--dp-row-maring)
}

.dp__calendar_item {
    text-align: center;
    flex-grow: 1;
    box-sizing: border-box;
    color: var(--dp-text-color)
}

.dp__calendar {
    position: relative
}

.dp__calendar_header_cell {
    border-bottom: thin solid var(--dp-border-color);
    padding: var(--dp-calendar-header-cell-padding)
}

.dp__cell_inner {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: var(--dp-cell-border-radius);
    height: var(--dp-cell-size);
    padding: var(--dp-cell-padding);
    width: var(--dp-cell-size);
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    position: relative
}

.dp__cell_inner:hover {
    transition: all .2s
}

.dp__cell_auto_range_start, .dp__date_hover_start:hover, .dp__range_start {
    border-end-end-radius: 0;
    border-start-end-radius: 0
}

.dp__cell_auto_range_end, .dp__date_hover_end:hover, .dp__range_end {
    border-end-start-radius: 0;
    border-start-start-radius: 0
}

.dp__range_end, .dp__range_start, .dp__active_date {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color)
}

.dp__cell_auto_range_end, .dp__cell_auto_range_start {
    border-top: 1px dashed var(--dp-primary-color);
    border-bottom: 1px dashed var(--dp-primary-color)
}

.dp__date_hover_end:hover, .dp__date_hover_start:hover, .dp__date_hover:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color)
}

.dp__cell_offset {
    color: var(--dp-secondary-color)
}

.dp__cell_disabled {
    color: var(--dp-secondary-color);
    cursor: not-allowed
}

.dp__range_between {
    background: var(--dp-hover-color);
    border-radius: 0;
    border: 1px solid var(--dp-hover-color)
}

.dp__range_between_week {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color);
    border-radius: 0;
    border-top: 1px solid var(--dp-primary-color);
    border-bottom: 1px solid var(--dp-primary-color)
}

.dp__today {
    border: 1px solid var(--dp-primary-color)
}

.dp__week_num {
    color: var(--dp-secondary-color);
    text-align: center
}

.dp__cell_auto_range {
    border-radius: 0;
    border-top: 1px dashed var(--dp-primary-color);
    border-bottom: 1px dashed var(--dp-primary-color)
}

.dp__cell_auto_range_start {
    border-inline-start: 1px dashed var(--dp-primary-color)
}

.dp__cell_auto_range_end {
    border-inline-end: 1px dashed var(--dp-primary-color)
}

.dp__calendar_header_separator {
    width: 100%;
    height: 1px;
    background: var(--dp-border-color)
}

.dp__calendar_next {
    margin-inline-start: var(--dp-multi-calendars-spacing)
}

.dp__marker_line, .dp__marker_dot {
    height: 5px;
    background-color: var(--dp-marker-color);
    position: absolute;
    bottom: 0
}

.dp__marker_dot {
    width: 5px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%)
}

.dp__marker_line {
    width: 100%;
    left: 0
}

.dp__marker_tooltip {
    position: absolute;
    border-radius: var(--dp-border-radius);
    background-color: var(--dp-tooltip-color);
    padding: 5px;
    border: 1px solid var(--dp-border-color);
    z-index: 99999;
    box-sizing: border-box;
    cursor: default
}

.dp__tooltip_content {
    white-space: nowrap
}

.dp__tooltip_text {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: var(--dp-text-color)
}

.dp__tooltip_mark {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--dp-text-color);
    color: var(--dp-text-color);
    margin-inline-end: 5px
}

.dp__arrow_bottom_tp {
    bottom: 0;
    height: 8px;
    width: 8px;
    background-color: var(--dp-tooltip-color);
    position: absolute;
    border-inline-end: 1px solid var(--dp-border-color);
    border-bottom: 1px solid var(--dp-border-color);
    transform: translate(-50%, 50%) rotate(45deg)
}

.dp__instance_calendar {
    position: relative;
    width: 100%
}

@media only screen and (max-width: 600px) {
    .dp__flex_display {
        flex-direction: column
    }
}

.dp__cell_highlight {
    background-color: var(--dp-highlight-color)
}

.dp__month_year_row {
    display: flex;
    align-items: center;
    height: var(--dp-month-year-row-height);
    color: var(--dp-text-color);
    box-sizing: border-box
}

.dp__inner_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: var(--dp-month-year-row-button-size);
    width: var(--dp-month-year-row-button-size);
    color: var(--dp-icon-color);
    text-align: center;
    border-radius: 50%
}

.dp__inner_nav svg {
    height: var(--dp-button-icon-height);
    width: var(--dp-button-icon-height)
}

.dp__inner_nav:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-icon-color)
}

[dir=rtl] .dp__inner_nav {
    transform: rotate(180deg)
}

.dp__inner_nav_disabled:hover, .dp__inner_nav_disabled {
    background: var(--dp-disabled-color);
    color: var(--dp-disabled-color-text);
    cursor: not-allowed
}

.dp--year-select, .dp__month_year_select {
    text-align: center;
    cursor: pointer;
    height: var(--dp-month-year-row-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--dp-border-radius);
    box-sizing: border-box;
    color: var(--dp-text-color)
}

.dp--year-select:hover, .dp__month_year_select:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color);
    transition: var(--dp-common-transition)
}

.dp__month_year_select {
    width: 50%
}

.dp--year-select {
    width: 100%
}

.dp__month_year_wrap {
    display: flex;
    width: 100%
}

.dp__year_disable_select {
    justify-content: space-around
}

.dp__overlay {
    width: 100%;
    background: var(--dp-background-color);
    transition: opacity 1s ease-out;
    z-index: 99999;
    font-family: var(--dp-font-family);
    color: var(--dp-text-color);
    box-sizing: border-box
}

.dp--overlay-absolute {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0
}

.dp--overlay-relative {
    position: relative
}

.dp__overlay_container::-webkit-scrollbar-track {
    box-shadow: var(--dp-scroll-bar-background);
    background-color: var(--dp-scroll-bar-background)
}

.dp__overlay_container::-webkit-scrollbar {
    width: 5px;
    background-color: var(--dp-scroll-bar-background)
}

.dp__overlay_container::-webkit-scrollbar-thumb {
    background-color: var(--dp-scroll-bar-color);
    border-radius: 10px
}

.dp__overlay:focus {
    border: none;
    outline: none
}

.dp__container_flex {
    display: flex
}

.dp__container_block {
    display: block
}

.dp__overlay_container {
    flex-direction: column;
    overflow-y: auto
}

.dp__time_picker_overlay_container {
    height: 100%
}

.dp__overlay_row {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    margin-inline-start: auto;
    margin-inline-end: auto;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    align-items: center
}

.dp__flex_row {
    flex: 1
}

.dp__overlay_col {
    box-sizing: border-box;
    width: 33%;
    padding: var(--dp-overlay-col-padding);
    white-space: nowrap
}

.dp__overlay_cell_pad {
    padding: var(--dp-common-padding) 0
}

.dp__overlay_cell_active {
    cursor: pointer;
    border-radius: var(--dp-border-radius);
    text-align: center;
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color)
}

.dp__overlay_cell {
    cursor: pointer;
    border-radius: var(--dp-border-radius);
    text-align: center
}

.dp__overlay_cell:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color);
    transition: var(--dp-common-transition)
}

.dp__cell_in_between {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color)
}

.dp__over_action_scroll {
    right: 5px;
    box-sizing: border-box
}

.dp__overlay_cell_disabled {
    cursor: not-allowed;
    background: var(--dp-disabled-color)
}

.dp__overlay_cell_disabled:hover {
    background: var(--dp-disabled-color)
}

.dp__overlay_cell_active_disabled {
    cursor: not-allowed;
    background: var(--dp-primary-disabled-color)
}

.dp__overlay_cell_active_disabled:hover {
    background: var(--dp-primary-disabled-color)
}

.dp__month_picker_header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: var(--dp-cell-size)
}

.dp__btn, .dp--time-invalid, .dp--time-overlay-btn {
    border: none;
    font: inherit;
    cursor: pointer;
    transition: var(--dp-common-transition);
    line-height: normal
}

.dp__time_input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-family: var(--dp-font-family);
    color: var(--dp-text-color)
}

.dp__time_col_reg_block {
    padding: 0 20px
}

.dp__time_col_reg_inline {
    padding: 0 10px
}

.dp__time_col_reg_with_button {
    padding: 0 15px
}

.dp__time_col_sec {
    padding: 0 10px
}

.dp__time_col_sec_with_button {
    padding: 0 5px
}

.dp__time_col {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.dp__time_col_block {
    font-size: var(--dp-time-font-size)
}

.dp__time_display {
    cursor: pointer;
    color: var(--dp-text-color);
    border-radius: var(--dp-border-radius);
    display: flex;
    align-items: center;
    justify-content: center
}

.dp__time_display:hover:enabled {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color)
}

.dp__time_display_block {
    padding: 0 3px
}

.dp__time_display_inline {
    padding: 5px
}

.dp__time_picker_inline_container {
    display: flex;
    width: 100%;
    justify-content: center
}

.dp__inc_dec_button {
    padding: 5px;
    margin: 0;
    height: var(--dp-time-inc-dec-button-size);
    width: var(--dp-time-inc-dec-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    color: var(--dp-icon-color);
    box-sizing: border-box
}

.dp__inc_dec_button svg {
    height: var(--dp-time-inc-dec-button-size);
    width: var(--dp-time-inc-dec-button-size)
}

.dp__inc_dec_button:hover {
    background: var(--dp-hover-color);
    color: var(--dp-primary-color)
}

.dp__inc_dec_button_inline {
    width: 100%;
    padding: 0;
    height: 8px;
    cursor: pointer;
    display: flex;
    align-items: center
}

.dp__inc_dec_button_disabled:hover, .dp__inc_dec_button_disabled {
    background: var(--dp-disabled-color);
    color: var(--dp-disabled-color-text);
    cursor: not-allowed
}

.dp__pm_am_button {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color);
    border: none;
    padding: var(--dp-common-padding);
    border-radius: var(--dp-border-radius);
    cursor: pointer
}

.dp__tp_inline_btn_bar {
    width: 100%;
    height: 4px;
    background-color: var(--dp-secondary-color);
    transition: var(--dp-common-transition);
    border-collapse: collapse
}

.dp__tp_inline_btn_top:hover .dp__tp_btn_in_r {
    background-color: var(--dp-primary-color);
    transform: rotate(12deg) scale(1.15) translateY(-2px)
}

.dp__tp_inline_btn_top:hover .dp__tp_btn_in_l {
    background-color: var(--dp-primary-color);
    transform: rotate(-12deg) scale(1.15) translateY(-2px)
}

.dp__tp_inline_btn_bottom:hover .dp__tp_btn_in_r {
    background-color: var(--dp-primary-color);
    transform: rotate(-12deg) scale(1.15) translateY(-2px)
}

.dp__tp_inline_btn_bottom:hover .dp__tp_btn_in_l {
    background-color: var(--dp-primary-color);
    transform: rotate(12deg) scale(1.15) translateY(-2px)
}

.dp--time-overlay-btn {
    background: none
}

.dp--time-invalid {
    background-color: var(--dp-disabled-color)
}

.dp__action_row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--dp-action-row-padding);
    box-sizing: border-box;
    color: var(--dp-text-color);
    flex-flow: row nowrap
}

.dp__action_row svg {
    height: var(--dp-button-icon-height);
    width: auto
}

.dp__selection_preview {
    display: block;
    color: var(--dp-text-color);
    font-size: var(--dp-preview-font-size);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.dp__action_buttons {
    display: flex;
    flex: 0;
    align-items: center;
    justify-content: flex-end;
    margin-inline-start: auto
}

.dp__action_button {
    display: inline-flex;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    padding: var(--dp-action-buttons-padding);
    line-height: var(--dp-action-button-height);
    margin-inline-start: 3px;
    height: var(--dp-action-button-height);
    cursor: pointer;
    border-radius: var(--dp-border-radius);
    font-size: var(--dp-preview-font-size);
    font-family: var(--dp-font-family)
}

.dp__action_select {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color)
}

.dp__action_select:hover {
    background: var(--dp-primary-color);
    transition: var(--dp-action-row-transtion)
}

.dp__action_select:disabled {
    background: var(--dp-primary-disabled-color);
    cursor: not-allowed
}

.dp__action_cancel {
    color: var(--dp-text-color);
    border: 1px solid var(--dp-border-color)
}

.dp__action_cancel:hover {
    border-color: var(--dp-primary-color);
    transition: var(--dp-action-row-transtion)
}

.dp__btn, .dp--time-overlay-btn, .dp--time-invalid {
    border: none;
    font: inherit;
    cursor: pointer;
    transition: var(--dp-common-transition);
    line-height: normal
}

:root {
    --dp-common-transition: all 0.1s ease-in;
    --dp-menu-padding: 6px 8px;
    --dp-animation-duration: 0.1s;
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1);
    --dp-transition-timing: ease-out;
    --dp-action-row-transtion: all 0.2s ease-in;
    --dp-font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    --dp-border-radius: 4px;
    --dp-cell-border-radius: 4px;
    --dp-transition-length: 22px;
    --dp-transition-timing-general: 0.1s;
    --dp-button-height: 35px;
    --dp-month-year-row-height: 35px;
    --dp-month-year-row-button-size: 25px;
    --dp-button-icon-height: 20px;
    --dp-calendar-wrap-padding: 0 5px;
    --dp-cell-size: 35px;
    --dp-cell-padding: 5px;
    --dp-common-padding: 10px;
    --dp-input-icon-padding: 35px;
    --dp-input-padding: 6px 30px 6px 12px;
    --dp-menu-min-width: 260px;
    --dp-action-buttons-padding: 1px 6px;
    --dp-row-maring: 5px 0;
    --dp-calendar-header-cell-padding: 0.5rem;
    --dp-multi-calendars-spacing: 10px;
    --dp-overlay-col-padding: 3px;
    --dp-time-inc-dec-button-size: 32px;
    --dp-font-size: 1rem;
    --dp-preview-font-size: 0.8rem;
    --dp-time-font-size: 2rem;
    --dp-action-button-height: 22px;
    --dp-action-row-padding: 8px
}

.dp__theme_dark {
    --dp-background-color: #212121;
    --dp-text-color: #fff;
    --dp-hover-color: #484848;
    --dp-hover-text-color: #fff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-disabled-color: #61a8ea;
    --dp-primary-text-color: #fff;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: #2d2d2d;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #737373;
    --dp-disabled-color-text: #d0d0d0;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;
    --dp-marker-color: #e53935;
    --dp-tooltip-color: #3e3e3e;
    --dp-highlight-color: rgb(0 92 178 / 20%)
}

.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1976d2;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%)
}

.dp__flex {
    display: flex;
    align-items: center
}

.dp__btn {
    background: none
}

.dp__main {
    font-family: var(--dp-font-family);
    user-select: none;
    box-sizing: border-box;
    position: relative;
    width: 100%
}

.dp__pointer {
    cursor: pointer
}

.dp__icon {
    stroke: currentcolor;
    fill: currentcolor
}

.dp__button {
    width: 100%;
    text-align: center;
    color: var(--dp-icon-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: var(--dp-common-padding);
    box-sizing: border-box;
    height: var(--dp-button-height)
}

.dp__button.dp__overlay_action {
    position: absolute;
    bottom: 0
}

.dp__button:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-icon-color)
}

.dp__button svg {
    height: var(--dp-button-icon-height);
    width: auto
}

.dp__button_bottom {
    border-bottom-left-radius: var(--dp-border-radius);
    border-bottom-right-radius: var(--dp-border-radius)
}

.dp__flex_display {
    display: flex
}

.dp__flex_display_with_input {
    flex-direction: column;
    align-items: flex-start
}

.dp__relative {
    position: relative
}

.calendar-next-enter-active, .calendar-next-leave-active, .calendar-prev-enter-active, .calendar-prev-leave-active {
    transition: all var(--dp-transition-timing-general) ease-out
}

.calendar-next-enter-from {
    opacity: 0;
    transform: translateX(var(--dp-transition-length))
}

.calendar-next-leave-to {
    opacity: 0;
    transform: translateX(calc(var(--dp-transition-length) * -1))
}

.calendar-prev-enter-from {
    opacity: 0;
    transform: translateX(calc(var(--dp-transition-length) * -1))
}

.calendar-prev-leave-to {
    opacity: 0;
    transform: translateX(var(--dp-transition-length))
}

.dp-menu-appear-bottom-enter-active, .dp-menu-appear-bottom-leave-active, .dp-menu-appear-top-enter-active, .dp-menu-appear-top-leave-active, .dp-slide-up-enter-active, .dp-slide-up-leave-active, .dp-slide-down-enter-active, .dp-slide-down-leave-active {
    transition: all var(--dp-animation-duration) var(--dp-transition-timing)
}

.dp-menu-appear-top-enter-from, .dp-menu-appear-top-leave-to, .dp-slide-down-leave-to, .dp-slide-up-enter-from {
    opacity: 0;
    transform: translateY(var(--dp-transition-length))
}

.dp-menu-appear-bottom-enter-from, .dp-menu-appear-bottom-leave-to, .dp-slide-down-enter-from, .dp-slide-up-leave-to {
    opacity: 0;
    transform: translateY(calc(var(--dp-transition-length) * -1))
}

.dp--arrow-btn-nav {
    transition: var(--dp-common-transition)
}


